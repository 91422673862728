import _ from 'lodash';
import IConfigProvider, { EnvType } from '../../data/models/confg-provider';

export function useConfigProvider(): IConfigProvider {
  return {
    env: process.env.NODE_ENV as EnvType,
    debug: _.isEqual(process.env.REACT_APP_DEBUG, 'true'),
    appName: process.env.REACT_APP_NAME || '',
    appVersion: process.env.REACT_APP_VERSION || '',
    baseUrl: process.env.REACT_APP_BASE_URL || '',
    firebase: {
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
      appId: process.env.REACT_APP_FIREBASE_APP_ID || '',
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
      baseUrl: process.env.REACT_APP_FIREBASE_BASE_URL || ''
    }
  };
}
