export enum RoutesEnum {
  LANDING_PAGE = '/',
  PAGE_NOT_FOUND = '*',
  LOGIN = '/login',
  RESET_PASSWORD = '/login/reset-password',
  DASHBOARD = '/dashboard',
  PROFILE_USER = '/profile',
  LIST_USERS = '/users',
  CREATE_USER = '/users/new',
  DETAIL_USER = '/users/:id/detail',
  UPDATE_USER = '/users/:id/update',
  LIST_GROUP = '/groups',
  CREATE_GROUP = '/groups/new',
  UPDATE_GROUP = '/groups/:id/update',
  DETAIL_GROUP = '/groups/:id/detail',
  EXAMPLE_DATAGRID = '/example-page',
  LIST_API_KEY = '/api-keys',
  CREATE_API_KEY = '/api-keys/new',
  DETAIL_API_KEY = '/api-keys/:id/detail',
  UPDATE_API_KEY = '/api-keys/:id/update'
}
