export enum MenuActionEnum {
  LOGOUT_ACTION = 'logout_action',
  PROFILE_ACTION = 'profile_action',
  HOME_ACTION = 'home_action',
  USERS_ACTION = 'users_action',
  GROUP_ACTION = 'group_action',
  INGESTION_ACTION = 'ingestion_action',
  ANALYTICS_ACTION = 'analytics_action',
  FULLFILMENT_ACTION = 'fullfilment_action',
  BOTBUILDER_ACTION = 'botbuilder_action',
  API_KEY_ACTION = 'api_key_action'
}
