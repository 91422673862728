import { Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import { RoutesEnum } from '../../shared/data/enums/routes-enum';
import useUtils from '../../shared/hooks/useUtils';

interface IBreadcrumbs {
  label: string;
  realPath: string;
  pathWithPlaceholder: string;
  isLast: boolean;
  routeEnum: string | null;
}
interface IParams {
  key: string | null;
  value: string | null;
}

const ignorePattern = ['/'];

const CustomBreadcrumbs: React.FC = (): JSX.Element => {
  const breadcrumbs = useReactRouterBreadcrumbs();
  const { t } = useTranslation(['common']);
  const { getEnumKeyByValue, enumPossibleValues } = useUtils();
  const params = useParams();
  const routes = enumPossibleValues(RoutesEnum) as unknown as RoutesEnum[];
  const arrayBreadcrumbs: IBreadcrumbs[] = [];
  const paramsObj = _.toArray(
    _.mapValues(params, (value, key): IParams => {
      return {
        key: key ?? null,
        value: value ?? null
      };
    })
  );

  const filteredBreadcrumbs = _.filter(breadcrumbs, (b): boolean => {
    return !_.some(ignorePattern, (p): boolean => p === b.match.pathname);
  });

  _.forEach(filteredBreadcrumbs, (b, index): void => {
    let route = getEnumKeyByValue(_.find(routes, (r): boolean => r === b.match.pathname) ?? '', RoutesEnum) ?? null;
    let path = b.match.pathname;
    if (_.isNil(route)) {
      const hasParams = _.some(paramsObj, (p): boolean => _.includes(b.match.pathname, p.value));
      if (hasParams) {
        _.forEach(paramsObj, (par): void => {
          path = _.replace(path, par.value ?? '', `:${par.key}`);
        });
        route = getEnumKeyByValue(_.find(routes, (r): boolean => r === path) ?? '', RoutesEnum) ?? null;
      }
    }
    if (!_.isNil(route)) {
      arrayBreadcrumbs.push({
        label: t(`breadcrumbs.${route}`),
        realPath: b.match.pathname,
        isLast: index + 1 === filteredBreadcrumbs.length,
        routeEnum: route,
        pathWithPlaceholder: path
      });
    }
  });

  const lastBreadcrumps = _.find(arrayBreadcrumbs, (b): boolean => b.isLast);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        {arrayBreadcrumbs.map(
          (b): JSX.Element =>
            !b.isLast ? (
              <Link key={b.realPath} to={b.realPath || ''} color="primary">
                <Typography sx={{ fontSize: 12 }} color="primary">
                  {b.label}
                </Typography>
              </Link>
            ) : (
              <Typography key={b.realPath} sx={{ fontSize: 12 }} color="primary">
                {''}
              </Typography>
            )
        )}
      </Breadcrumbs>
      <Typography variant="h1" paragraph color="primary">
        {lastBreadcrumps?.label}
      </Typography>
    </>
  );
};

export default CustomBreadcrumbs;
