import { Analytics, Insights, ReduceCapacity, SmartToy, SupervisorAccount, TextSnippet } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import KeyIcon from '@mui/icons-material/Key';
import { MenuActionEnum } from '../../data/enums/menu-action-enum';
import { MenuItemObj } from '../../data/models/menu-item';
import { UserPermissions } from '../enums/permissions-enum';
import { UserRoles } from '../enums/roles-enum';

const DrawerItems: MenuItemObj[] = [
  {
    id: 'home',
    image: <HomeIcon color="primary" />,
    text: 'menu.home',
    action: MenuActionEnum.HOME_ACTION
  },
  {
    id: 'users',
    image: <SupervisorAccount color="primary" />,
    text: 'menu.users',
    action: MenuActionEnum.USERS_ACTION,
    roles: [UserRoles.ADMIN, UserRoles.GROUP_ADMIN]
  },
  {
    id: 'groups',
    image: <ReduceCapacity color="primary" />,
    text: 'menu.groups',
    action: MenuActionEnum.GROUP_ACTION,
    roles: [UserRoles.ADMIN]
  },
  {
    id: 'apiKey',
    image: <KeyIcon color="primary" />,
    text: 'menu.api_keys',
    action: MenuActionEnum.API_KEY_ACTION,
    roles: [UserRoles.ADMIN]
  },
  {
    id: 'ingestion',
    image: <TextSnippet color="primary" />,
    text: 'menu.ingestion',
    action: MenuActionEnum.INGESTION_ACTION,
    roles: [UserRoles.GROUP_ADMIN, UserRoles.USER],
    permissions: [UserPermissions.DOC_INGESTION_VIEWER, UserPermissions.DOC_INGESTION_EDITOR]
  },
  {
    id: 'analytics',
    image: <Analytics color="primary" />,
    text: 'menu.analytics',
    action: MenuActionEnum.ANALYTICS_ACTION,
    roles: [UserRoles.GROUP_ADMIN, UserRoles.USER],
    permissions: [UserPermissions.ANALYTICS_VIEWER, UserPermissions.ANALYTICS_EDITOR]
  },
  {
    id: 'fullfilment',
    image: <Insights color="primary" />,
    text: 'menu.fullfilment',
    action: MenuActionEnum.FULLFILMENT_ACTION,
    roles: [UserRoles.GROUP_ADMIN, UserRoles.USER],
    permissions: [UserPermissions.FULFILLMENT_VIEWER, UserPermissions.FULFILLMENT_EDITOR]
  },
  {
    id: 'botbuilder',
    image: <SmartToy color="primary" />,
    text: 'menu.bot_builder',
    action: MenuActionEnum.BOTBUILDER_ACTION,
    roles: [UserRoles.GROUP_ADMIN, UserRoles.USER],
    permissions: [UserPermissions.BOTBUILDER_VIEWER, UserPermissions.BOTBUILDER_EDITOR]
  }
];

export default DrawerItems;
